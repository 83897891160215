<template>
  <div class="shipments">
    <MobileTable
      v-model:selection="store.state.shipment.selectedShipments"
      label="Listado de Encomiendas Recibidas"
      :columns="shipment.columns"
      :data="store.state.shipment.shipments"
      :linked="true"
      :onSelect="onDetail"
    />
    <DesktopTable
      v-model:selection="store.state.shipment.selectedShipments"
      label="Listado de Encomiendas Recibidas"
      :columns="shipment.columns"
      :data="store.state.shipment.shipments"
      :linked="true"
      :onSelect="onDetail"
    />
    <Actions :items="shipment.actions" />
    <BasicModal v-model:visible="shipment.showModal">
      <template v-slot:dialog>
        <BasicTitle title="Detalle de la encomienda" />
        <div class="container-form">
          <BasicLabel label="Apartamento" />
          <BorderInput :label="shipment.data.apartment" :disabled="true" />
          <FormError />
          <BasicLabel label="Destinatario" />
          <BorderInput :label="shipment.data.destinatary" :disabled="true" />
          <FormError />
          <BasicLabel label="Estado" />
          <BorderInput :label="shipment.data.status" :disabled="true" />
          <FormError />
          <BasicLabel label="Tipo" />
          <BorderInput :label="shipment.data.type" :disabled="true" />
          <FormError />
          <BasicLabel label="Recibido por" />
          <BorderInput :label="shipment.data.recipient" :disabled="true" />
          <FormError />
          <BasicLabel label="Entregado por" />
          <BorderInput :label="shipment.data.deliveredBy" :disabled="true" />
          <FormError />
          <BasicLabel label="Fecha" />
          <BorderInput :label="shipment.data.date" :disabled="true" />
          <FormError />
          <BasicLabel label="Comentario" />
          <OutlinedTextArea v-model="shipment.data.comment" :disabled="true" />
          <FormError />
          <!-- <BasicLogo
            width="350"
            :src="shipment.data.image"
            alt="Comprobante de Pago"
          /> -->
        </div>
      </template>
    </BasicModal>
  </div>
</template>

<script>
import { useRouter, useRoute } from "vue-router";
import Actions from "../../components/shared/Actions.vue";
import MobileTable from "../../widgets/tables/MobileTable";
import DesktopTable from "../../widgets/tables/DesktopTable";
import BasicModal from "../../widgets/modal/BasicModal";
import BasicLogo from "../../widgets/logo/BasicLogo";
import FormError from "../../widgets/error/FormError";
import BorderInput from "../../widgets/input/BorderInput";
import BasicLabel from "../../widgets/label/BasicLabel";
import BasicTitle from "../../widgets/title/BasicTitle";
import OutlinedTextArea from "../../widgets/textarea/OutlinedTextArea";
import { onBeforeMount, reactive } from "vue";
import store from "../../store";
import { openMode, actions, dates } from "../../constants";

export default {
  components: {
    MobileTable,
    DesktopTable,
    Actions,
    BasicModal,
    BasicLogo,
    FormError,
    BorderInput,
    BasicLabel,
    BasicTitle,
    OutlinedTextArea,
  },

  setup() {
    const router = useRouter();
    const route = useRoute();
    const onCreate = () => {
      store.commit("setOpenMode", openMode.CREATE);
      router.push("/encomiendas/nuevo");
    };
    const onDetail = (data) => {
      shipment.data.apartment = data.apartment;
      shipment.data.destinatary = data.destinatary;
      shipment.data.status = data.status;
      shipment.data.type = data.type;
      shipment.data.deliveredBy = data.deliveredBy;
      shipment.data.recipient = data.recipient;
      shipment.data.date = `${dates.dateToString(data.date)}`;
      shipment.data.comment = data.comment;
      shipment.showModal = true;
    };
    const onRemove = async () => {
      if (store.state.shipment.selectedShipments.length !== 1) {
        store.state.toast.add({
          severity: "info",
          summary: "",
          detail: "Debe seleccionar un registro!",
          life: 5000,
        });
      } else if (
        store.state.shipment.selectedShipments[0].userId !==
          store.state.auth.user.id ||
        store.state.shipment.selectedShipments[0].statusId === 3
      ) {
        store.state.toast.add({
          severity: "info",
          summary: "",
          detail: "No puede eliminar la encomienda",
          life: 5000,
        });
      } else {
        store.commit("setLoading", true);

        const response = await store.dispatch(actions.shipmentActions.remove, {
          userId: store.state.auth.user.id,
          id: store.state.shipment.selectedShipments[0].id,
        });

        if (response.ok) {
          store.commit("setSelectedShipments", []);
          await store.dispatch(
            actions.shipmentActions.findAll,
            store.state.general.selectedBuilding
          );
          store.commit("setLoading", false);
        } else {
          store.state.toast.add({
            severity: "error",
            summary: "",
            detail: response.message,
            life: 5000,
          });
          store.commit("setLoading", false);
        }
      }
    };
    const onChange = async (id) => {
      if (store.state.shipment.selectedShipments.length !== 1) {
        store.state.toast.add({
          severity: "info",
          summary: "",
          detail: "Debe seleccionar un registro!",
          life: 5000,
        });
      } else {
        store.commit("setLoading", true);

        const response = await store.dispatch(actions.shipmentActions.status, {
          statusId: id,
          id: store.state.shipment.selectedShipments[0].id,
        });

        if (response.ok) {
          store.commit("setSelectedShipments", []);
          await store.dispatch(
            actions.shipmentActions.findAll,
            store.state.general.selectedBuilding
          );
          store.commit("setLoading", false);
        } else {
          store.state.toast.add({
            severity: "error",
            summary: "",
            detail: response.message,
            life: 5000,
          });
          store.commit("setLoading", false);
        }
      }
    };

    const shipment = reactive({
      showModal: false,
      data: {
        apartment: "",
        destinatary: "",
        status: "",
        type: "",
        deliveredBy: "",
        recipient: "",
        date: "",
        comment: "",
        doc: "",
      },
      columns: [
        {
          field: "apartment",
          header: "Apartamento",
          sort: true,
          center: true,
        },
        {
          field: "destinatary",
          header: "Destinatario",
          sort: true,
          center: true,
        },
        {
          field: "status",
          header: "Estado",
          sort: true,
          center: true,
        },
        {
          field: "type",
          header: "Tipo",
          sort: true,
          center: true,
        },
        {
          field: "deliveredBy",
          header: "Entregado por",
          sort: true,
          center: true,
        },
        {
          field: "recipient",
          header: "Recibido por",
          sort: true,
          center: true,
        },
        {
          field: "date",
          header: "Fecha",
          sort: true,
          date: true,
        },
        {
          field: "comment",
          header: "Comentario",
          sort: true,
        },
      ],
      actions: [
        {
          name: "Nueva Encomienda",
          action: onCreate,
          hidden: router
            .getRoutes()
            .find((route) => route.path === "/encomiendas/nuevo"),
        },
        {
          name: "Recibido",
          action: () => onChange(2),
          hidden: router
            .getRoutes()
            .find((route) => route.path === "/encomiendas/nuevo"),
        },
        {
          name: "Entregado",
          action: () => onChange(3),
          hidden: router
            .getRoutes()
            .find((route) => route.path === "/encomiendas/nuevo"),
        },
        {
          name: "Eliminar",
          action: () => onRemove(),
          hidden: router
            .getRoutes()
            .find((route) => route.path === "/encomiendas/nuevo"),
        },
      ],
    });

    onBeforeMount(async () => {
      store.commit("setLoading", true);
      store.commit("setSelectedShipments", []);
      const finded = store.state.general.breadcrumbs.find(
        (item) => item.to === route.path
      );
      if (!finded)
        store.commit("addBreadcrumb", { label: route.name, to: route.path });
      await store.dispatch(
        actions.shipmentActions.findAll,
        store.state.general.selectedBuilding
      );
      store.commit("setLoading", false);
    });

    return { store, shipment, onDetail };
  },
};
</script>

<style scoped></style>
